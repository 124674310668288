/* --- HEADERS, Ps, LINKS... --- */

h1 {
    font-family: "Open Sans";
    font-weight: lighter;

    margin: 3%;
}

h2 {
    font-family: "Open Sans";
    font-weight: bolder;
}

hr {
    height: 0;
    color: rgb(107, 0, 107);
}

.link {
    text-decoration: none;
    color: rgb(233, 233, 233);
    font-weight: lighter;
}

.link:hover {
    color: rgb(151, 53, 122);

    transition: 0.2s ease-out;
}

.nav-link {
    text-decoration: none;
    color: rgb(216, 216, 216);
    font-weight: lighter;
    font-size: x-large;
}

.nav-link:hover {
    color: rgb(151, 53, 122);

    transition: 0.2s ease-out;
}

/* --- COMPONENTS --- */

.layout {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    min-height: 100vh;

    text-align: center;
}

.header {
    background-image: repeating-linear-gradient(
        -90deg,
        rgb(109, 0, 124),
        rgb(77, 12, 77),
        rgb(34, 6, 34),
        rgb(20, 20, 20)
    );
    background-size: 200%;
    background-repeat: no-repeat;
    animation: Diagonal alternate 10s infinite;

    color: rgb(255, 223, 248);
    text-shadow: 2px 1px 6px rgba(150, 150, 150, 1);
    padding: 10px 20px;

    text-align: center;
}

@keyframes Diagonal {
    0% {background-position: 0% 50%}
    100% {background-position: 100% 50%}
}

.navbar {

    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    
    padding: 10px 5px;

    border: 1px transparent rgb(197, 143, 197);

    -webkit-box-shadow: 0px 12px 17px -8px rgba(136,0,194,1);
    -moz-box-shadow: 0px 12px 17px -8px rgba(136,0,194,1);
    box-shadow: 0px 12px 17px -8px rgba(136,0,194,1);
}

.footer {
    margin: 0;
    
    text-align: center;
}

.title {
    margin-bottom: 40px;
}

.post-element {
    text-align: left;

    border: 1px solid rgb(153, 9, 153);

    -webkit-box-shadow: 9px 9px 17px -8px rgba(136,0,194,1);
    -moz-box-shadow: 9px 9px 17px -8px rgba(136,0,194,1);
    box-shadow: 9px 9px 17px -8px rgba(136,0,194,1);

    margin-bottom: 10vh;
    /* so it won't get pushed to edge of border */
    padding: 0 15px;

    font-family: "Open Sans";
    font-weight: lighter;
}

/* --- POST CREATOR -- */

.post-creator{

}

.post-form {
    padding-top: 20px;
    text-align: center;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.post-input {
    font-family: "Open Sans";
    text-align: center;
    margin: 20px 10px;
    height: 10vh;

    background: rgb(53, 53, 53);
    color: white;

    transition: 0.5s;
}

.post-input:focus {
    transition: 0.5s;
    background: rgb(19, 19, 19);
}

.post-button {
    margin: 20px 10px;
    height: 5vh;
    max-width: 60%;
    
    border: none;
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    text-decoration: none;
    color: #b1b1b1;
    background: purple;
    box-shadow: 0;
    /**/
    transition: 0.2s;
}

.post-button:hover {
    transition: 0.2s;
    box-shadow: 0 0 10px purple, 0 0 20px purple, 0 0 40px purple;
}

.created-post {
    text-align: center;
    margin-bottom: 4vh;
}

.hidden-form {
    display: none;
}

.full-hr {
    width: 70vw;
}
