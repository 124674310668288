h2{
    font-weight: lighter;
}

a{
    text-decoration: none;
    color: rgb(233, 233, 233);
    font-weight: lighter;
}

a:hover {
    color: rgb(151, 53, 122);

    transition: 0.2s ease-out;
}

p{
    font-weight: lighter;
    font-size: larger;
}

img {
    max-height: 90vh;
    max-width: 90%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}


.postlist {
    width: 80%;
    margin: auto;
    padding-top: 80px;
}

.page-content{
    width: 80%;
    margin: auto;
    text-align: left;
}


.notfound {
    text-align: center;
}

/* --- POST ITSELF --- */

.post-title {
    text-align: center;
}

.post-meta {
    text-align: center;
}

.post {
    width: 80%;
    margin: auto;
    text-align: left;

    border: 1px solid rgb(167, 77, 167);

    -webkit-box-shadow: 9px 9px 17px -8px rgba(136,0,194,1);
    -moz-box-shadow: 9px 9px 17px -8px rgba(136,0,194,1);
    box-shadow: 9px 9px 17px -8px rgba(136,0,194,1);

    margin-top: 15vh;
    margin-bottom: 10vh;
    /* so it won't get pushed to edge of border */
    padding: 0 15px;

    font-family: "Open Sans";
    font-weight: lighter;
}

.pcs-h1 {
    margin-top: 10vh;
    margin-bottom: 1vh;
}
.h1-link{
    text-decoration: underline;
}

.apps-link{
    text-decoration: underline;
}
